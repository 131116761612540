import moment from "moment/moment";
import consumer_apply_applyHotelJourney from '@/lib/data-service/haolv-default/consumer_apply_applyHotelJourney';
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
export default {
    data() {
        return {
            dialogVisible: false,
            searchKey: '',
            "departTime": "",
            "returnTime": "",
            "toCityId": "",
            list: [],
            myUserInfo: {},
        }
    },
    props: {},
    components: {},
    created() {
    },
    mounted() {
        get_user_info().then(res=>{
            this.myUserInfo = res.datas;
        })
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        changeDepartTime(val) {
            let day = moment(val).format('YYYY年MM月DD日')
            return day
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        init(params) {
            this.departTime = params.departTime;
            this.returnTime = params.returnTime;
            this.toCityId = params.toCityId;
        },
        show() {
            this.dialogVisible = true;
        },
        hide() {
            this.dialogVisible = false;
        },

        _search() {
            if (!this.searchKey) {
                this.list = [];
                return;
            }
            consumer_apply_applyHotelJourney({
                "departTime": this.departTime,
                "returnTime": this.returnTime,
                "toCityId": this.toCityId,
                "userName": this.searchKey,
            }).then(res=>{
                let data = res.datas;
                let list = [];
                data.forEach(value=>{
                    value.journeys.forEach(value1 => {
                        value.active = value1;
                        let hasMyself = false;
                        value1.staffList.forEach(value2=>{
                            if (value2.userId === this.myUserInfo.id) {
                                hasMyself = true;
                            }
                        });
                        if (!hasMyself) {
                            list.push(value);
                        }
                    })
                })
                this.list = list;
            }).catch(e=>{
                this.list = [];
            })
        },
        _getCurrentItem(item, index) {
            this.$emit('getShareRoomInfo', item);
            this.hide();
        },
    }
}
